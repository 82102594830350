<template>
  <div class="p-6 w-full">
    <div class="bg-white rounded-lg shadow-lg max-w-2xl mx-auto">
      <div class="px-6 py-4 flex flex-col text-left">
        <h2 class="font-bold text-xl mb-2">Stock Take Sheets</h2>

        <div
            v-if="stockTakeStore.stockTakeSheets.length > 0"
            class="flex flex-col text-left">
          <select
              class="block w-full mb-4 text-lg bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 mr-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              v-model="selectedSheet">
            <option
                value="0"
                selected
                disabled>
              Select Stock Take Sheet
            </option>
            <option
                v-for="sheet in sheets"
                :key="sheet.id"
                :value="sheet.id">
              {{ sheet.file_name }}
            </option>
          </select>

          <router-link
              :to="{ name: 'Products', params: { sheetId: selectedSheet } }"
              v-if="selectedSheet !== 0"
              @click="logViewProducts"
              class="w-56 bg-blue-900/80 hover:bg-blue-900 text-white text-center font-bold py-2 px-4 text-xl rounded mb-2"
          >View Products</router-link
          >
        </div>

        <div
            v-if="stockTakeStore.stockTakeSheets.length === 0"
            class="block self-center w-full md:w-3/4 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 mr-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
          No Stock Take Sheets Found
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useStockTakeStore } from "@/stores/stockTakeStore";

const stockTakeStore = useStockTakeStore();
const sheets = computed(() => stockTakeStore.stockTakeSheets);
const selectedSheet = ref(0);
const isOnline = ref(navigator.onLine);
let intervalId;

// Connectivity Check
const checkConnectivity = async () => {
  try {
    const response = await fetch("https://jsonplaceholder.typicode.com/posts", { method: "HEAD" });
    isOnline.value = response.ok;
  } catch (error) {
    isOnline.value = false;
  }
};

// Log Activity Function
const logActivity = (action, details) => {
  stockTakeStore.logActivity(action, details);
};

// Watch Selected Sheet Changes
watch(selectedSheet, (newValue) => {
  if (newValue !== 0) {
    const sheet = sheets.value.find((sheet) => sheet.id === newValue);
    if (sheet) {
      logActivity("Sheet selected for counting", {
        sheetName: sheet.file_name,
        sheetId: sheet.id,
      });
    }
  }
});

// Log View Products Button Click
const logViewProducts = () => {
  const sheet = sheets.value.find((sheet) => sheet.id === selectedSheet.value);
  if (sheet) {
    logActivity("View Products button pressed", {
      sheetName: sheet.file_name,
      sheetId: sheet.id,
    });
  }
};

onMounted(() => {
  intervalId = setInterval(checkConnectivity, 30000);
  checkConnectivity();

  window.addEventListener("online", () => { isOnline.value = true; });
  window.addEventListener("offline", () => { isOnline.value = false; });
});

onUnmounted(() => {
  clearInterval(intervalId);
  window.removeEventListener("online", () => { isOnline.value = true; });
  window.removeEventListener("offline", () => { isOnline.value = false; });
});
</script>

