import { createApp, watch, computed } from "vue";
// import { createApp, watch, computed } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./style.css";

import { createPinia } from "pinia";

import { useStockTakeStore } from "@/stores/stockTakeStore";

const pinia = createPinia();

createApp(App).use(router).use(pinia).mount("#app");

// Hydrate the store with product counts from localStorage data
const stockTakeStore = useStockTakeStore();
stockTakeStore.hydrateAllData();

window.addEventListener("online", () => {
    const stockTakeStore = useStockTakeStore();
    stockTakeStore.logActivity("Network connected");
});

window.addEventListener("offline", () => {
    const stockTakeStore = useStockTakeStore();
    stockTakeStore.logActivity("Network disconnected");
});

const userId = new URLSearchParams(window.location.search).get('user_id');
if (userId) {
    const stockTakeStore = useStockTakeStore();
    stockTakeStore.logActivity("User authenticated and redirected", { userId });
}



watch(
  pinia.state,
  (state) => {
    // console.log("state: ", state.stockTakeStore);
    
    const stockTakeSheetsArray = computed(() => state.stockTakeStore.stockTakeSheets);
    // console.log("stockTakeSheetsArray: ", stockTakeSheetsArray);
    const productsArray = computed(() => state.stockTakeStore.products);
    // console.log("productsArray: ", productsArray);

    localStorage.setItem("stockTakeSheets", JSON.stringify(stockTakeSheetsArray.value));
    localStorage.setItem("products", JSON.stringify(productsArray.value));
    localStorage.setItem("selectedCamera", (state.stockTakeStore.selectedCamera));
    localStorage.setItem("stockTakeHistory", JSON.stringify(state.stockTakeStore.stockTakeHistory));
  },
  {
    deep: true,
  }
);